














































































import { Component, Vue } from 'vue-property-decorator'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsContainer from '@/components/VsContainer/Index.vue'

@Component({
    name: 'Automations',
    components: {
        VsSectionHeader,
        VsLoader,
        VsContainer,
    },
})
export default class extends Vue {
    private loading = false
    private automations: any[] = []
    private total = 0

    private pagination = {
        page: 1,
        itemsPerPage: 10,
    }

    private features = {
        categories: [],
        subCategories: [],
        colors: [],
    }

    private filters: {
        search: string
        filters: any
        type: string
    } = {
        search: '',
        filters: {
            category: null,
            color: null,
            subCategory: null,
            users: [],
            tags: [],
        },
        type: '',
    }

    $refs!: any
}
